import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosAdmin } from "../../../Api/Api";

function SingleProjectReport() {
  const [projectData, setProjectData] = useState();
  const [projectId, setProjectId] = useState();

  const handleProjectChange = (e) => {
    setProjectId(e.target.value);
  };

  const fetchData = async () => {
    try {
      const response = await axiosAdmin.get("allprojects?status=false");
      setProjectData(response?.data?.FindProject);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.replace("/admin/login");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex justify-center">
      <div className="">
        {projectData && projectData.length > 0 ? (
          <FormControl className="w-56">
            <InputLabel id="demo-simple-select-label">
              SELECT PROJECT
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={projectId}
              label="SELECT PROJECT"
              onChange={handleProjectChange}
            >
              {projectData.map((item) => (
                <MenuItem value={item._id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              No projects found
            </InputLabel>
            <Select id="demo-simple-select" label="SELECT PROJECT"></Select>
          </FormControl>
        )}
      </div>
    </div>
  );
}

export default SingleProjectReport;
