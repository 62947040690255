import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import downloadPdf from './downloadPDF';
import ReturnButton from '../../CommonComponents/Return/ReturnButton';
import SingleProjectReport from './SingleProjectReport';

const options = [
    { value: 'totalreport', label: 'Total Report' },
    { value: 'workreport', label: 'Purchases Report' },
    { value: 'attendancereport', label: 'Labour Attendance Report' },
    { value: 'staffattendancereport', label: 'Staff Attendance Report' },
    { value: 'recievedcashreport', label: 'Recieved Cash Report' },
    { value: 'billsreport', label: 'Bills Report' },
    { value: 'singleprojectreport', label: 'Single Project Report' }
];

function Report() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);

    const handleDownloadPdf = (reportType) => {
        setLoading(true);
        downloadPdf(reportType.value, `${reportType.label}-report.pdf`, startDate, endDate, () => setLoading(false));
    };

    return (
        <>
        <ReturnButton navigation={"/admin/officedetails"}/>
        {/* <SingleProjectReport/> */}
        <h1 className="flex justify-center text-3xl font-bold">Download Report</h1>
        <div className="flex flex-row items-center justify-center gap-8 mb-24">
            <div className="mb-4">
                <Select 
                    value={selectedReport} 
                    onChange={setSelectedReport} 
                    options={options} 
                    placeholder="Select Report Type"
                    className="w-60"
                />
            </div>
            <div className="mb-4 flex items-center">
                <label className="mr-2">Start Date:</label>
                <DatePicker className="p-2 border border-gray-300 rounded" dateFormat={'dd-MM-yyyy'} selected={startDate} onChange={date => setStartDate(date)} />
            </div>
            <div className="mb-4 flex items-center">
                <label className="mr-2">End Date:</label>
                <DatePicker className="p-2 border border-gray-300 rounded" dateFormat={'dd-MM-yyyy'} selected={endDate} onChange={date => setEndDate(date)} />
            </div>
            <button 
                className="px-4 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600 cursor-pointer" 
                onClick={() => handleDownloadPdf(selectedReport)} 
                disabled={loading || !selectedReport}
            >
                {loading ? 'Loading...' : 'Download Report'}
            </button>
        </div>
        </>
    );
}

export default Report;
