import React from 'react'

function Footer() {
  return (
    <footer className="bg-blue-900 text-white py-6 w-full ">
      <div className="container mx-auto text-center">
        <p>Created by Allsoft Technologies</p>
      </div>
    </footer>
  )
}

export default Footer
