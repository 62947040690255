import React from 'react'
import Header from '../../../../components/AdminComponents/Header/Header'
import OwnerExpense from '../../../../components/AdminComponents/Office/Owner/OwnerExpense'
import Footer from '../../../../components/AdminComponents/Footer/Footer'


function OwnerExpenseList() {
  return (
    <div>
    <Header headers={'OWNER EXPENSE LIST'}/>
   <OwnerExpense/>
   <div>
    <Footer/>
   </div>
    </div>
  )
}

export default OwnerExpenseList