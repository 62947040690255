import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 relative">
      {/* Background circle */}
      <div className="absolute w-[400px] h-[400px] rounded-full bg-blue-500 opacity-20 -top-20 -left-20"></div>
      <div className="absolute w-[300px] h-[300px] rounded-full bg-red-500 opacity-20 -bottom-20 -right-20"></div>

      <h1 className="text-6xl font-extrabold text-gray-800 mb-4 animate-bounce">404</h1>
      <p className="text-2xl font-semibold text-gray-700 mb-8">
        Oops! Page Not Found
      </p>
      <p className="text-lg text-gray-600 mb-8 text-center max-w-md">
        The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
      </p>
      <Link to="/admin" className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-600 transition duration-300">
        Go to Home
      </Link>
    </div>
  );
}

export default NotFoundPage;
