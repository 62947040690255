import React from 'react';
import Login from '../../../components/AdminComponents/Login/Login';
import Footer from '../../../components/AdminComponents/Footer/Footer';

function LoginPage() {
  return (
    <div className=''>
      <Login />
    </div>
  );
}

export default LoginPage;
