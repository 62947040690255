import React, { useState } from "react";
import Footer from "../../AdminComponents/Footer/Footer";
import Buttons from "../../CommonComponents/Button/Buttons";
import { useNavigate } from "react-router-dom";
import TextFields from "../../CommonComponents/TextFields/TextFields";
import { useCookies } from "react-cookie";
import { axiosAdmin } from "../../../Api/Api";
import fieldValidate from "../../../Validation/Validate";
import { useDispatch } from "react-redux";
import { AdminAction } from "../../../Stores/AdminAuth";
import toast, { Toaster } from "react-hot-toast";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Button,
} from "@mui/material";

function Login() {
  const [cookies, setCookies] = useCookies([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailError = fieldValidate("email", email);
    setEmailError(emailError);
    if (emailError) {
      return;
    }

    const passwordError = fieldValidate("password", password);
    setPasswordError(passwordError);
    if (passwordError) {
      return;
    }

    axiosAdmin
      .post("login", { email, password })
      .then((response) => {
        if (response?.data?.success) {
          const currentDate = new Date();
          const ageInMinutes = 60;
          const expirationDate = new Date(
            currentDate.getTime() + ageInMinutes * 60 * 1000
          );

          setCookies("AdminsecretKey", response?.data?.adminSignin?.token, {
            path: "/",
            expires: expirationDate,
          });
          dispatch(
            AdminAction.AddAdmin({ token: response?.data?.adminSignin?.token })
          );

          toast.success(response?.data?.message);
          navigate("/admin/dashboard");
        } else {
          setError(response?.data?.message)
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.message)
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div
      className="flex sm:justify-end justify-center items-center h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('/Images/back.jpg')" }}
    >
      <Toaster position="top-center" reverseOrder={false} />
      <div
        className="flex flex-col p-4 items-center w-72 h-96 sm:me-20 me-0 rounded-xl"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.85)",
          backdropFilter: "blur(0px)",
        }}
      >
        <img
          src="/Images/podu.png"
          alt="Poduppil Logo"
          style={{ width: "150px", height: "auto" }}
        />
        <Box
          component="form"
          onKeyDown={handleKeyDown}
          sx={{ mt: 2, maxWidth: 400, width: "100%" }}
        >
          <TextField
            size="small"
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            sx={{ width: "100%" }}
            margin="normal"
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            size="small"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            sx={{ width: "100%" }}
            margin="normal"
            error={!!passwordError}
            helperText={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <RemoveRedEyeIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <p className="text-red-500 text-sm flex justify-center">{error}</p>}
          <div className="flex justify-center mt-2">
            <Button sx={{ width: "100%" }} size="small" variant="contained" color="primary" onClick={handleSubmit}>
              Login
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default Login;
